<template>
  <CFormGroup
          v-bind="{append, prepend, validFeedback, invalidFeedback,
        tooltipFeedback, description, wrapperClasses, class: computedClasses}"
  >
    <template #label>
      <slot name="label">
        <label v-if="label" :for="safeId" :class="labelClasses">
          {{label}}
        </label>
      </slot>
    </template>
    <template #input>
      <TinyMCEEditor
              :init="editorOptions"
              v-model="state"
      />

    </template>

    <template
            v-for="slot in $options.slots"
            #[slot]
    >
      <slot :name="slot"/>
    </template>
  </CFormGroup>
</template>

<script>
import CFormGroup from '@coreui/vue/src/components/form/CFormGroup'
import { inputProps as props } from '@coreui/vue/src/components/form/form-props'
import * as allFormMixins from '@coreui/vue/src/components/form/form-mixins'
import TinyMCEEditor from '@tinymce/tinymce-vue'
import commons from '@/commons'

const mixins = Object.values(allFormMixins)

export default {
  name: 'WysiwygEditor',
  slots: [
    'prepend',
    'prepend-content',
    'append-content',
    'append',
    'label-after-input',
    'valid-feedback',
    'invalid-feedback',
    'description'
  ],
  inheritAttrs: false,
  components: { CFormGroup, TinyMCEEditor },
  mixins,
  props,
  data () {
    return {
      state: this.value,
      syncTimeout: null,
      editorOptions: {}
    }
  },
  created () {
    this.editorOptions = {
      height: 400,
      menubar: true,
      language: 'de',
      plugins: [
        'advlist autolink lists link image charmap print preview anchor fullpage',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount image imagetools'
      ],
      branding: false,
      toolbar:
          'undo redo | image | formatselect | bold italic backcolor | \
          alignleft aligncenter alignright alignjustify | \
          bullist numlist outdent indent | removeformat | help',
      imagetools_cors_hosts: ['cloud.makler-anfragen.immo', 'cloud.stage.makler-anfragen.immo', 'localhost'],
      file_picker_types: 'image',
      images_reuse_filename: true,
      automatic_uploads: true,
      extended_valid_elements:'style,link[href|rel]',
      custom_elements:'style,link,~link',
      forced_root_block : '',
      force_br_newlines : true,
      force_p_newlines : false,
      images_upload_handler: (blobInfo, success, failure, progress) => {
        var xhr, formData;

        xhr = new XMLHttpRequest();
        xhr.withCredentials = false;
        xhr.open('POST', process.env.VUE_APP_BACKEND_BASE_URL + '/file/upload');

        xhr.upload.onprogress = function (e) {
          progress(e.loaded / e.total * 100);
        };

        xhr.onload = function() {
          var json;

          if (xhr.status === 403) {
            failure('HTTP Error: ' + xhr.status, { remove: true });
            return;
          }

          if (xhr.status < 200 || xhr.status >= 300) {
            failure('HTTP Error: ' + xhr.status);
            return;
          }

          json = JSON.parse(xhr.responseText);

          if (!json || typeof json.fileDownloadUrl != 'string') {
            failure('Invalid JSON: ' + xhr.responseText);
            return;
          }

          success(json.fileDownloadUrl);
        };

        xhr.onerror = function () {
          failure('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
        };

        formData = new FormData();
        formData.append('file', blobInfo.blob(), blobInfo.filename());

        xhr.setRequestHeader('Authorization', 'Bearer ' + commons.getFromStorage('jwtToken'));
        const realmContext = commons.getFromStorage('realmContext');
        if (realmContext) {
          formData.append('realmContext', realmContext);
        }

        xhr.send(formData);
      },
      /* and here's our custom image picker*/
      file_picker_callback: function (cb, value, meta) {
        var input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.onchange = function () {
          var file = this.files[0];

          var reader = new FileReader();
          reader.onload = function () {
            /*
              Note: Now we need to register the blob in TinyMCEs image blob
              registry. In the next release this part hopefully won't be
              necessary, as we are looking to handle it internally.
            */
            var id = file.name.split('.').slice(0, -1).join('.');
            var blobCache =  tinymce.activeEditor.editorUpload.blobCache;
            var base64 = reader.result.split(',')[1];
            var blobInfo = blobCache.create(id, file, base64);
            blobCache.add(blobInfo);

            /* call the callback and populate the Title field with the file name */
            cb(blobInfo.blobUri(), { title: file.name });
          };
          reader.readAsDataURL(file);
        };

        input.click();
      }
    }
  },
  watch: {
    state (newValue) {
      this.$emit('input', newValue)
    }
  }
}
</script>
<style>
  .prt-quill .ql-editor {
    min-height: 140px;
  }
</style>
